import { createElement } from "@syncfusion/ej2-base";
import { Button } from "@syncfusion/ej2-buttons";
import { DropDownButton } from "@syncfusion/ej2-splitbuttons";
import axios from "axios";

/**
 * Represents document editor title bar.
 */
export class TitleBar {
  tileBarDiv;
  documentTitle;
  documentTitleContentEditor;
  export;
  close;
  print;
  open;
  documentEditor;
  isRtl;
  dialogComponent;
  constructor(element, docEditor, isShareNeeded, isRtl, dialogComponent) {
    //initializes title bar elements.
    this.tileBarDiv = element;
    this.documentEditor = docEditor;
    this.isRtl = isRtl;
    this.dialogComponent = dialogComponent;
    this.initializeTitleBar(isShareNeeded);
    this.wireEvents();
  }
  initializeTitleBar = (isShareNeeded) => {
    let downloadText;
    let downloadToolTip;
    let printText;
    let closeToolTip;
    let printToolTip;
    let openText;
    let documentTileText;
    let storeText;
    let storeToolTip;
    if (!this.isRtl) {
      downloadText = "Download";
      downloadToolTip = "Download this document.";
      storeText = "Save";
      storeToolTip = "Save this document.";
      printText = "Print";
      printToolTip = "Print this document (Ctrl+P).";
      closeToolTip = "Close this document";
      openText = "Open";
      documentTileText = "Document Name. Click or tap to rename this document.";
    }
    // tslint:disable-next-line:max-line-length
    this.documentTitle = createElement("label", {
      id: "documenteditor_title_name",
      styles:
        "font-weight:400;text-overflow:ellipsis;white-space:pre;overflow:hidden;user-select:none;cursor:text",
    });
    // tslint:disable-next-line:max-line-length
    this.documentTitleContentEditor = createElement("div", {
      id: "documenteditor_title_contentEditor",
      className: "single-line",
    });
    this.documentTitleContentEditor.appendChild(this.documentTitle);
    this.tileBarDiv.appendChild(this.documentTitleContentEditor);
    this.documentTitleContentEditor.setAttribute("title", documentTileText);
    let btnStyles =
      "float:right;background: transparent;box-shadow:none; font-family: inherit;border-color: transparent;" +
      "border-radius: 2px;color:inherit;font-size:12px;text-transform:capitalize;height:28px;font-weight:400;margin-top: 2px;";
    // tslint:disable-next-line:max-line-length
    this.close = this.addButton(
      "e-icons e-close e-de-padding-right",
      "",
      btnStyles,
      "de-close",
      closeToolTip,
      false
    );
    this.print = this.addButton(
      "e-de-icon-Print e-de-padding-right",
      printText,
      btnStyles,
      "de-print",
      printToolTip,
      false
    );
    this.store = this.addButton(
      "e-de-icon-Open e-de-padding-right",
      storeText,
      btnStyles,
      "de-open",
      storeToolTip,
      false
    );
    this.open = this.addButton(
      "e-de-icon-Open e-de-padding-right",
      openText,
      btnStyles,
      "de-open",
      openText,
      false
    );
    let items = [
      { text: "Syncfusion Document Text (*.sfdt)", id: "sfdt" },
      { text: "Word Document (*.docx)", id: "word" },
      { text: "Word Template (*.dotx)", id: "dotx" },
      { text: "Plain Text (*.txt)", id: "txt" },
    ];
    // tslint:disable-next-line:max-line-length
    this.export = this.addButton(
      "e-de-icon-Download e-de-padding-right",
      downloadText,
      btnStyles,
      "documenteditor-share",
      downloadToolTip,
      true,
      items
    );
    if (!isShareNeeded) {
      this.export.element.style.display = "none";
    } else {
      this.open.element.style.display = "none";
    }
    if (this.dialogComponent == null) this.close.element.style.display = "none";
  };
  setTooltipForPopup() {
    // tslint:disable-next-line:max-line-length
    document
      .getElementById("documenteditor-share-popup")
      .querySelectorAll("li")[0]
      .setAttribute(
        "title",
        "Download a copy of this document to your computer as an SFDT file."
      );
    // tslint:disable-next-line:max-line-length
    document
      .getElementById("documenteditor-share-popup")
      .querySelectorAll("li")[1]
      .setAttribute(
        "title",
        "Download a copy of this document to your computer as a DOCX file."
      );
    // tslint:disable-next-line:max-line-length
    document
      .getElementById("documenteditor-share-popup")
      .querySelectorAll("li")[2]
      .setAttribute(
        "title",
        "Download a copy of this document to your computer as a DOTX file."
      );
    // tslint:disable-next-line:max-line-length
    document
      .getElementById("documenteditor-share-popup")
      .querySelectorAll("li")[3]
      .setAttribute(
        "title",
        "Download a copy of this document to your computer as a TXT file."
      );
  }
  wireEvents = () => {
    this.store.element.addEventListener("click", this.storeFile);
    this.print.element.addEventListener("click", this.onPrint);
    this.close.element.addEventListener("click", this.onClose);
    this.open.element.addEventListener("click", (e) => {
      if (e.target.id === "de-open") {
        let fileUpload = document.getElementById("uploadfileButton");
        fileUpload.value = "";
        fileUpload.click();
      }
    });
    this.documentTitleContentEditor.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.documentTitleContentEditor.contentEditable = "false";
        if (this.documentTitleContentEditor.textContent === "") {
          this.documentTitleContentEditor.textContent = "Document1";
        }
      }
    });
    this.documentTitleContentEditor.addEventListener("blur", () => {
      if (this.documentTitleContentEditor.textContent === "") {
        this.documentTitleContentEditor.textContent = "Document1";
      }
      this.documentTitleContentEditor.contentEditable = "false";
      this.documentEditor.documentName = this.documentTitle.textContent;
    });
    this.documentTitleContentEditor.addEventListener("click", () => {
      this.updateDocumentEditorTitle();
    });
  };
  updateDocumentEditorTitle = () => {
    this.documentTitleContentEditor.contentEditable = "true";
    this.documentTitleContentEditor.focus();
    window.getSelection().selectAllChildren(this.documentTitleContentEditor);
  };
  // Updates document title.
  updateDocumentTitle = () => {
    if (this.documentEditor.documentName === "") {
      this.documentEditor.documentName = "Untitled";
    }
    this.documentTitle.textContent = this.documentEditor.documentName;
  };
  // tslint:disable-next-line:max-line-length
  addButton(iconClass, btnText, styles, id, tooltipText, isDropDown, items) {
    let button = createElement("button", { id: id, styles: styles });
    this.tileBarDiv.appendChild(button);
    button.setAttribute("title", tooltipText);
    if (isDropDown) {
      // tslint:disable-next-line:max-line-length
      let dropButton = new DropDownButton(
        {
          select: this.onExportClick,
          items: items,
          iconCss: iconClass,
          cssClass: "e-caret-hide",
          content: btnText,
          open: () => {
            this.setTooltipForPopup();
          },
        },
        button
      );
      return dropButton;
    } else {
      let ejButton = new Button(
        { iconCss: iconClass, content: btnText },
        button
      );
      return ejButton;
    }
  }
  onPrint = () => {
    this.documentEditor.print();
  };
  onClose = () => {
    this.dialogComponent.hide();
  };
  onExportClick = (args) => {
    let value = args.item.id;
    switch (value) {
      case "word":
        this.save("Docx");
        break;
      case "sfdt":
        this.save("Sfdt");
        break;
      case "txt":
        this.save("Txt");
        break;
      case "dotx":
        this.save("Dotx");
        break;
    }
  };
  save = (format) => {
    // tslint:disable-next-line:max-line-length
    this.documentEditor.save(
      this.documentEditor.documentName === ""
        ? "sample"
        : this.documentEditor.documentName,
      format
    );
  };
  storeFile = (format) => {
    // tslint:disable-next-line:max-line-length
    // this.documentEditor.save(
    //   this.documentEditor.documentName === ""
    //     ? "sample"
    //     : this.documentEditor.documentName,
    //   format
    // );
    this.documentEditor.saveAsBlob("Docx").then(async (data) => {
      let formData = new FormData();
      formData.append("transcripts", data);
      formData.append("path", this.documentEditor.documentName);

      formData.append("manual", this.documentEditor.manual);
      formData.append("file_id", this.documentEditor.file_id);

      axios({
        method: "post",
        url: "https://doc-app.capricontechnology.com/api/upload-sync-fusion",
        // url: "http://localhost:1337/api/upload-sync-fusion",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("File Saved Successfully!!");
          window.onbeforeunload = null;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
      // Replace your running Url here

      // req.open("POST", "localhost:1337/api/upload-sync-fusion", true);
      // req.onreadystatechange = () => {
      //   if (req.readyState === 4) {
      //     if (req.status === 200 || req.status === 304) {
      //       console.log("Reuest", req);
      //       console.log("Saved sucessfully");
      //     }
      //   }
      // };
      // req.send(formData);
    });
  };
}
