import { createRoot } from "react-dom/client";
import "./index.css";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { TitleBar } from "./title-bar";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  // "Ngo9BigBOggjHTQxAR8/V1NBaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjXH5fcnRXQmVcU0d1Xw=="
  // "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpfcXRSQmBZUkJzW0s="
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdec3VRQmlcUERxVkM="
  // "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpdcXRTRmFYWUJyW0I="
);

DocumentEditorContainerComponent.Inject(Toolbar);
// tslint:disable:max-line-length
const Default = () => {
  const [fileData, setFileData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    console.log("LAWDA 123");
    rendereComplete();
  }, []);
  let hostUrl =
    "https://services.syncfusion.com/react/production/api/documenteditor/";
  let container = useRef(null);
  let titleBar;
  //

  const fetchFile = async (fileUrl) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const file = new File([blob], "downloaded.docx", { type: blob.type });
    return file;
  };

  const handleFetchAndUpload = async (file_name, manual) => {
    let fileUrl = "";

    if (manual || manual === "true") {
      fileUrl = searchParams.get("file_url");
      // "https://storage.googleapis.com/doc-app-static-site/" + file_name;
    } else {
      fileUrl =
        "https://storage.googleapis.com/doc-app-static-site/reports/" +
        file_name;
      // "https://storage.googleapis.com/doc-app-static-site/reports/d88b259257df4a96889f0c2e5e6f4235.docx"; // Replace with your file URL
    }
    try {
      const file = await fetchFile(fileUrl);

      // Now you have a File object similar to what you get from an input element
      setFileData(file);

      // Create FormData and append the file
      const formData = new FormData();
      formData.append("File", file);

      // Send the FormData to the backend
      const response = await fetch(
        "https://word-editor.capricontechnology.com/api/uploads",
        {
          method: "POST",
          body: formData,
        }
      );
      console.log("response", response);

      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      console.error("Error fetching and uploading file:", error);
    }
  };

  const onLoadDefault = async () => {
    // tslint:disable
    let defaultDocument = {};
    console.log(
      "FIle name before and after",
      searchParams.get("file"),
      decodeURIComponent(searchParams.get("file"))
    );
    if (searchParams.get("file") && searchParams.get("manual")) {
      defaultDocument = await handleFetchAndUpload(
        decodeURIComponent(searchParams.get("file")),
        Boolean(searchParams.get("manual"))
      );
      console.log("INSIDE", defaultDocument);

      // tslint:enable
      container.current.documentEditor.open(JSON.stringify(defaultDocument));

      container.current.documentEditor.documentName = decodeURIComponent(
        searchParams.get("file")
      );
      container.current.documentEditor.manual = searchParams.get("manual");
      container.current.documentEditor.file_id = decodeURIComponent(
        searchParams.get("file_id")
      );
    }
    titleBar.updateDocumentTitle();
    container.current.documentChange = () => {
      titleBar.updateDocumentTitle();
      container.current.documentEditor.focusIn();
    };
  };
  const rendereComplete = () => {
    window.onbeforeunload = function () {
      return "Want to save your changes?";
    };

    container.current.documentEditor.pageOutline = "#E0E0E0";
    container.current.documentEditor.acceptTab = true;
    container.current.documentEditor.resize();
    titleBar = new TitleBar(
      document.getElementById("documenteditor_titlebar"),
      container.current.documentEditor,
      true
    );

    // onLoadDefault();

    // let http = new XMLHttpRequest();
    // //add your url in which you want to open document inside the ""
    // let content = { fileUrl: "" };
    // let baseurl =
    //   "https://storage.googleapis.com/doc-app-static-site/reports/d88b259257df4a96889f0c2e5e6f4235.docx";
    // http.open("GET", baseurl, true);
    // http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // http.onreadystatechange = () => {
    //   if (http.readyState === 4) {
    //     if (http.status === 200 || http.status === 304) {
    //       //open the SFDT text in Document Editor
    //       console.log("HTTP", http.responseText);
    //       container.current.documentEditor.open(http.responseText);
    //     }
    //   }
    // };
    // http.send(JSON.stringify(content));
    onLoadDefault();
  };
  return (
    // <div className="control-panel">
    <div className="control-section">
      <div id="documenteditor_titlebar" className="e-de-ctn-title"></div>
      <div id="documenteditor_container_body">
        <DocumentEditorContainerComponent
          id="container"
          ref={container}
          style={{ display: "block" }}
          height={"780px"}
          serviceUrl={hostUrl}
          enableToolbar={true}
          locale="en-US"
        />
      </div>
    </div>
    // </div>
  );
};
export default Default;

const root = createRoot(document.getElementById("sample"));
root.render(
  <>
    <BrowserRouter>
      <Default />
    </BrowserRouter>
  </>
);
